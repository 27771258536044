<template>
  <header class="bar">
    <img
      class="bar-logo"
      src="@/assets/images/logo.svg"
      alt="">
    <div class="bar-right">
      <p class="bar-right-text">
        Development
      </p>
      <p class="bar-right-text">
        Marketing
      </p>
      <p class="bar-right-text">
        UI/UX
      </p>
      <p class="bar-right-text">
        Our team
      </p>
      <p class="bar-right-text">
        Our projects
      </p>
      <p class="bar-right-text">
        Contacts
      </p>
    </div>
  </header>
</template>

<script setup lang="ts">
</script>

<style lang="scss" scoped>
.bar {
  position: fixed;
  width: 100%;
  height: get-vw(90px);
  display: flex;
  align-items: center;
  justify-content: space-between;
  top: 0;
  background: rgba(2,1,2,.95);
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
  transition: all .25s ease;
  z-index: 10;
  &-logo {
    margin-left: get-vw(24px);
    width: get-vw(170px);
    height: auto;
  }
  &-right {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-right: get-vw(120px);
    &-text{
      color: #fff;
      text-decoration: none;
      display: flex;
      padding: 0 get-vw(20px);
      font-size: get-vw(20px);
      word-spacing: get-vw(1px);
      font-family: "Diatype", sans-serif;
      font-weight: 600!important;
      cursor: pointer;
      -webkit-font-smoothing: antialiased;
      transition: color .25s;
      &:hover {
        transition: color .25s;
        color: #00ffbd;
      }
    }
  }
}
</style>
